import './App.css';
import logo from './image/eromai.png';

function App() {
  return (
    <div className="App">
      <nav className="navbar">
        <div className="logo">
          <img src={logo} alt="이롬아이 (Eromai LC.)" style={{ width: '150px', height: '50px' }} />
        </div>
        <div className="nav-links">
          <a href="#about">이롬아이란?</a>
          <a href="#services">서비스</a>
          <a href="#contact">연락처</a>
        </div>
      </nav>

      <section className="hero">
        <img src={logo} alt="이롬아이 로고" />
        <h1> - 이롬아이 - </h1>
        <h3>"이로움(이롬)"을 주는 인공지능(아이, AI) </h3>

        <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
          <br />
          * AI 기술은 인간의 일자리를 대체하는 목적으로 쓰여서는 안되며, 
          인류 복지를 위한 방향으로 활용되어야 한다는 신념 아래 올바른
          사용 방안을 끊임없이 모색해 왔습니다. 
          <br /><br />
          * 인공지능 기술로 사회, 복지에 투여되는 비용을 절감하고, 
          인력과 자원의 효율성을 극대화 하며 
          지역 맞춤형 AI 생활 관리 및 생활 보조 서비스를 위한 
          최선의 방안을 연구 개발하고 있습니다.
          <br /><br />
          * 이를 통해 지역 주민들의 일상 생활 불편을 해소시키고, 
          주민 상호간 소통의 질을 향상시키며, 
          나아가 지역사회의 지속 가능한 발전에 기여하고자 합니다.
        </p>
      </section>

      <section id="이롬아이에 소개" className="about">
        <h2>이롬아이 목표</h2>
        <p>이롬아이는 사람을 이롭게 하는 인공지능이라는 뜻으로 첨단 기술에 소외될 수 있는 분들이 
          더욱 쉽고 재미있게 기술을 활용하여 평상시 생활에 도움을 받을 수 있도록 할 목적으로 설립되었습니다.</p>
      </section>

      <section id="services" className="services">
        <h2>이롬아이 서비스</h2>
        <div className="service-grid">
          <div className="service-card">
            <h3>에이전트 생활관리 </h3>
            <p>사용자와 음성으로 소통하는 소형 디바이스를 활용해서 사용자의 평소 </p>
          </div>
          <div className="service-card">
            <h3>지능형 홈</h3>
            <p>편리한 지능형 농업, 지능형 홈 서비스를 개발하고 관리합니다.</p>
          </div>
          <div className="service-card">
            <h3>사회복지 서비스 보조</h3>
            <p>지능형 서비스 도움이 필요한 사회복지 연계 서비스 제공합니다</p>
          </div>
        </div>
      </section>

      <section id="contact" className="contact">
        <h2>연락처</h2>
        <p>이롬아이는 언제나 여러분의 의견을 기다립니다. 언제든지 연락주세요.</p>
        <div className="contact-links">
          <a href="mailto:tyoh@eromai.space" className="email">이메일 : tyoh@eromai.space</a>
          <br/>
          <a href="tel:010-8500-1137" className="phone">전화 : 010-8500-1137</a>
        </div>
      </section>

      <footer>
        <p>&copy; 2024 (유)이롬아이, Eromai LC. All rights reserved.
        <br/>사업자 등록번호: 123-45-67890
        <br/>대표자: 오태양
        <br/>사업장 소재지: 전남 진도군 응덕길 31-12
        <br/>개인정보 보호 책임자: 오태양,  개인정보 보호 담당자: 오태양
        <br/>전화: +82-10-8500-1137
        <br/>이메일: tyoh@eromai.space</p>
      </footer>
    </div>
  )
}

export default App;
